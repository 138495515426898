
.filter {
  position: relative;

  display: flex;
  flex-direction: column;
}

.filter__btn {
  position: relative;

  align-self: flex-end;
  max-width: 175px;
  margin-bottom: 20px;
  padding-left: 30px;

  font-weight: 600;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;
  letter-spacing: .02em;


  @media (max-width: $md) {
    align-self: center;

    font-size: 12px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 20px;
    height: 24px;

    background: url("../img/icon/arr-toggle.png") no-repeat;
    background-size: 100%;


    @media (max-width: $md) {
      width: 16px;
      height: 20px;
    }
  }
}

.filter__variation-wrap {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 15;

  display: flex;
  flex-direction: column;
  padding: 37px 60px;

  background-color: $c-white;
  border-radius: 30px;
  visibility: hidden;
  opacity: 0;

  transition: .3s;

  &.active {
    visibility: visible;
    opacity: 1;
  }


  @media (max-width: $md) {
    top: 35px;

    width: 100%;
    padding: 13px 85px;

    border-radius: 20px;
  }
}

.filter__variation {
  margin-bottom: 0;

  font-weight: 400;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: #828282;
  font-style: normal;
  letter-spacing: .02em;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover,
  &:active {
    font-weight: 800;

    border-bottom: 1px solid #828282;
  }

  @media (max-width: $md) {
    font-size: 14px;
    text-align: center;
  }
}
