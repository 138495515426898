.nav {
  position: relative;

  &::after {
    @extend %gold;

    content: "";
    bottom: 0;

    display: block;
    max-width: 1100px;
    height: 4px;
    margin: 0 auto;

    border-radius: 7px;

    @media (max-width: $md) {
      display: none;
    }
  }
}

.nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;

  @media (max-width: $md) {
    flex-direction: column;
  }
}

.nav.header__nav--active .nav__list {
  // animation: .2s linear .1s forwards slideLeft;
}

.nav__item {
  margin-bottom: 20px;

  transition: .2s;

  &:not(:last-child) {
    margin-right: 39px;

    @media (max-width: 1376px) {
      margin-right: 55px;
    }

    @media (max-width: $lg) {
      margin-right: 68px;
    }

    @media (max-width: 870px) {
      margin-right: 40px;
    }
  }
}

.nav__link {
  font-weight: 600;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: $c-white;
  letter-spacing: .02em;
  white-space: nowrap;

  transition: .3s;

  &:hover {
    color: $c-accent;
    text-decoration: none;
  }

  @media (max-width: $md) {
    font-size: 16px;
  }
}
