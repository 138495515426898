.card {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;

  background: linear-gradient(220.08deg, #aa0901 -4.32%, #d10c01 92.32%);
  border-radius: 26px;

  transition: .3s;

  &::after,
  &::before {
    content: "";
    position: absolute;

    display: block;
    width: 100%;
    height: 35px;
  }

  &::after {
    bottom: 0;
    left: 0;

    background: url("../img/cards/ellipse-bottom.png") no-repeat;
    background-size: cover;


    @media (max-width: $md) {
      background: url("../img/cards/ellipse-bottom-mob.png") no-repeat;
    }
  }

  &::before {
    top: 0;
    left: 0;

    background: url("../img/cards/ellipse-top.png") no-repeat;
    background-size: cover;

    @media (max-width: $md) {
      background: url("../img/cards/ellipse-top-mob.png") no-repeat;
    }
  }


  &:hover {
    text-decoration: none;

    filter: drop-shadow(2px 2px 20px rgba(255, 185, 29, .8));
  }

  @media (max-width: $xl) {
    padding-bottom: 30px;
  }


  @media (max-width: $md) {
    padding: 0 15px 20px;
  }
}

.card__dot-right,
.card__dot-left {
  position: absolute;
  top: 15px;

  display: block;
  width: 24px;
  height: calc(100% - 15px - 38px);

  background: url("../img/cards/ellipse.png") repeat-y;

  @media (max-width: $md) {
    top: 30.5px;

    width: 12px;
    height: calc(100% - 30px - 35px);

    background: url("../img/cards/ellipse-mob.png") repeat-y;
  }
}

.card__dot-left {
  left: 0;
}

.card__dot-right {
  // right: -3px;
  right: 0;

  @media (max-width: $md) {
    right: 4px;
  }
}

.card__wrap-img {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  margin: 20px 0;


  @media (max-width: $md) {
    margin: 15px 0;
  }
}

.card__desc {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  text-shadow: 0 3.25647px 3.25647px rgba(0, 0, 0, .25);
}

.card__title {
  max-width: 235px;
  min-height: 55px;
  margin-bottom: 15px;

  font-weight: 600;
  font-size: 26px;
  line-height: 1;
  font-family: $ff-main;
  text-align: center;
  color: $c-white;

  @media (max-width: $md) {
    min-height: 35px;

    font-size: 16px;
  }
}
.card__desc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__desc-text {
  position: relative;

  padding-left: 34px;

  font-weight: 500;
  font-size: $fz-main;
  line-height: 1.3;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;

  @media (max-width: $md) {
    padding-left: 28px;

    font-size: 12px;
  }

  &--time {
    margin-bottom: 12px;

    @media (max-width: $md) {
      margin-bottom: 8px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      display: block;
      width: 20px;
      height: 20px;

      background: url("../img/icon/time.svg") center center no-repeat;
      background-size: 100%;

      @media (max-width: $md) {
        width: 15px;
        height: 15px;

        background-size: 100%;
      }
    }
  }

  &--age {
    margin-bottom: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      display: block;
      width: 25px;
      height: 25px;

      background: url("../img/icon/baby.svg") center center no-repeat;
      background-size: 100%;

      @media (max-width: $md) {
        width: 18px;
        height: 14px;

        background-size: 100%;
      }
    }
  }
}

.card__desc-text br {
  display: none;

  @media (max-width: $md) {
    display: block;
  }
}

.card__desc-cost {
  font-weight: 800;
  font-size: 28px;
  line-height: 1.1;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;

  @media (max-width: $md) {
    font-size: $fz-main;
  }
}
