// ////////////////////////////////////////// Roboto
@font-face {
  font-weight: 400;
  font-family: Gilroy;
  font-style: normal;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Regular.woff2),
  url(../font/Gilroy/Gilroy-Regular.woff);
}

@font-face {
  font-weight: 500;
  font-family: Gilroy;
  font-style: normal;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Medium.woff2),
  url(../font/Gilroy/Gilroy-Medium.woff);
}

@font-face {
  font-weight: 600;
  font-family: Gilroy;
  font-style: normal;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-SemiBold.woff22),
  url(../font/Gilroy/Gilroy-SemiBold.woff);
}

@font-face {
  font-weight: 700;
  font-family: Gilroy;
  font-style: normal;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Bold.woff2),
  url(../font/Gilroy/Gilroy-Bold.woff);
}

@font-face {
  font-weight: 700;
  font-family: Gilroy;
  font-style: italic;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-BoldItalic.woff2),
  url(../font/Gilroy/Gilroy-BoldItalic.woff);
}

@font-face {
  font-weight: 800;
  font-family: Gilroy;
  font-style: normal;

  font-display: swap;
  src: url(../font/Gilroy/Gilroy-ExtraBold.woff),
  url(../font/Gilroy/Gilroy-ExtraBold.woff);
}
