.animals {
  position: relative;

  padding-top: 112px;
  padding-bottom: 160px;

  @media (max-width: $md) {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;

    display: block;
  }

  &::before {
    top: -92px;
    left: 0;

    width: 766px;
    height: 1357px;

    background: url("../img/bc/ann-left.png") no-repeat;

    @media (max-width: $lg) {
      top: 15px;

      background: url("../img/bc/tinsel-left-tab.png") no-repeat;
    }

    @media (max-width: $md) {
      top: -217px;

      width: 130px;
      height: 393px;

      background: url("../img/bc/tinsel-left-mob.png") no-repeat;
    }
  }

  &::after {
    top: 209px;
    right: 0;

    width: 556px;
    height: 959px;

    background: url("../img/bc/ann-right.png") no-repeat;

    @media (max-width: $lg) {
      background: none;
    }
  }
}

.animals__container {
  position: relative;
  z-index: 10;
}

.animals__title {
  padding-bottom: 10px;
}

.animals__wrap-text {
  margin-bottom: 40px;
}

.animals__text {
  &:nth-child(2) {
    margin-bottom: 40px;
  }
  &.text--mg {
    margin-bottom: 115px;

    @media (max-width: $md) {
      margin-bottom: 0;
    }
  }
}

.animals__wrap-video {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: $md) {
    margin-right: -35px;
    margin-left: -35px;
  }
}

.animals__wrap-video iframe {
  border: none;

  @media (max-width: $md) {
    width: 323px;
    height: 182px;
  }
}

.animals__list {
  margin: 20px 0 25px;
}

.animals__item {
  position: relative;

  padding-left: 30px;

  font-weight: 400;
  font-size: $fz-main;
  line-height: 1.25;
  font-family: $ff-main;
  color: $c-white;

  @media (max-width: $md) {
    // margin-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;

    display: block;
    width: 4px;
    height: 4px;

    background-color: $c-white;
    border-radius: 50%;
    transform: translate(-50%, 0);
  }
}

.animals__wrap-btn {
  position: relative;
  z-index: 10;

  display: flex;
  justify-content: center;
}

.animals__btn {
  @media (max-width: $md) {
    min-width: 286px;
    padding: 5px 30px;
  }
}

.animals__wrap-video + .animals__wrap-text .animals__text:nth-child(2) {
  margin-bottom: 25px;
}
