.social {
  display: flex;
  justify-content: space-between;
  min-width: 217px;

  @media (max-width: $md) {
    justify-content: center;
  }
}

.social__link {
  @extend %gold;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;

  border-radius: 6px;

  transition: .2s;

  & svg {
    width: 23px;
    height: 23px;

    fill: $c-white;

    @media (max-width: $md) {
      width: 17px;
      height: 17px;
    }
  }

  &:hover {
    @extend %gold;
  }

  @media (max-width: $md) {
    width: 28px;
    height: 28px;
  }
}
