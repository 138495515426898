.tinsel {
  // position: absolute;
  // right: 0;
  // bottom: -40%;

  // display: block;
  // width: 603px;
  // height: 100%;

  // background: url("../img/bc/tinsel-right.png") no-repeat;
  // background-size: 100%;

  // @media (max-width: $lg) {
  //   width: 395px;

  //   background: url("../img/bc/tinsel-right-tab.png") no-repeat;
  // }

  // @media (max-width: $md) {
  //   bottom: -80%;

  //   width: 125px;

  //   background: url("../img/bc/tinsel-right-mob.png") no-repeat;
  // }
}

.tinsel__left {
  position: absolute;
  top: 1085px;
  left: 0;

  width: 623px;
  height: 100%;

  background: url("../img/bc/tinsel-left.png") no-repeat;

  @media (max-width: $lg) {
    width: 415px;

    background: url("../img/bc/tinsel-left-tab.png") no-repeat;
  }

  @media (max-width: $md) {
    width: 130px;

    background: url("../img/bc/tinsel-left-mob.png") no-repeat;
  }
}

.tinsel__right {
  position: absolute;
  right: 0;
  bottom: -41%;

  display: block;
  width: 603px;
  height: 100%;

  background: url("../img/bc/tinsel-right.png") no-repeat;
  background-size: 100%;

  @media (max-width: $lg) {
    width: 395px;

    background: url("../img/bc/tinsel-right-tab.png") no-repeat;
  }

  @media (max-width: $md) {
    bottom: -80%;

    width: 125px;

    background: url("../img/bc/tinsel-right-mob.png") no-repeat;
  }
}
