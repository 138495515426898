.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $md) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.pagination__arrow-prev,
.pagination__arrow-next {
  display: block;
  width: 30px;
  height: 30px;

  background: url("../img/icon/arrow-left.png") no-repeat;
  background-size: 100%;


  @media (max-width: $md) {
    width: 100%;
    height: 20px;

    background: url("../img/icon/arrow-left-mob.png") no-repeat;
  }

  &:hover {
    opacity: .5;
  }
}

.pagination__arrow-prev {
  margin-right: 30px;

  @media (max-width: $md) {
    margin-right: 20px;
  }
}

.pagination__arrow-next {
  margin-left: 30px;

  transform: rotate(180deg);

  @media (max-width: $md) {
    margin-left: 20px;
  }
}

.pagination__links {
  display: flex;
  justify-content: space-between;
  min-width: 265px;

  @media (max-width: $xl) {
    min-width: 215px;
  }
}

.pagination__page {
  font-weight: 700;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;
  letter-spacing: .02em;

  transition: .3s;

  &:hover {
    color: $c-accent;
    text-decoration: none;
  }

  @media (max-width: $md) {
    font-size: 12px;
  }

  &--current {
    color: $c-accent;
  }
}
