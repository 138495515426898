.about-us {
  position: relative;

  padding-bottom: 220px;

  @media (max-width: $lg) {
    padding-bottom: 180px;
  }

  @media (max-width: $sm) {
    padding-bottom: 60px;
  }
}

.about-us__title {
  margin-bottom: 120px;

  @media (max-width: $md) {
    margin-bottom: 40px;
  }
}

.about-us__wrap-text {
  position: relative;
  z-index: 15;
}

.about-us__wrap-img {
  position: absolute;
  bottom: -80px;
  left: 0;
  z-index: 10;

  & img {
    min-width: 530px;

    @media (max-width: $lg) {
      object-fit: cover;
    }

    @media (max-width: $md) {
      min-width: 450px;
    }

    @media (max-width: $sm) {
      min-width: 100%;
      height: 784px;

      object-fit: none;
    }
  }

  @media (max-width: $lg) {
    bottom: -80px;
    left: 48%;
  }

  @media (max-width: $md) {
    left: 35%;
  }

  @media (max-width: $sm) {
    position: relative;
    bottom: 145px;
    left: 0;
  }
}

.about-us__wrap-btn {
  position: relative;
  z-index: 20;

  display: flex;
  justify-content: center;

  @media (max-width: $sm) {
    margin-top: -345px;
  }
}
