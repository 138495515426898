.breadcrumb {
  max-width: 1380px;
}

.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  margin: 55px 0 85px;

  @media (max-width: $lg) {
    margin-bottom: 50px;
  }

  @media (max-width: $md) {
    margin: 40px 0;
  }
}

.breadcrumb__item {
  position: relative;

  font-weight: 400;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;
  white-space: nowrap;

  @media (max-width: $md) {
    font-weight: 400;
    font-size: 12px;
  }

  &:not(:last-child) {
    margin-right: 35px;

    @media (max-width: $md) {
      margin-right: 18px;
    }
  }

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -25px;

    display: block;
    width: 15px;
    height: 10px;

    background: url("../img/icon/arrow-right.svg") center center no-repeat;
    background-size: 100%;
    transform: translate(0, -50%);

    @media (max-width: $md) {
      right: -15px;

      width: 8px;
      height: 5px;
    }
  }
}

.breadcrumb__link {
  color: inherit;

  &:hover {
    color: $c-accent;
    text-decoration: none;
  }
}
