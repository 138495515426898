@charset "UTF-8";

@font-face {
  font-weight: 400;
  font-family: Gilroy;
  font-style: normal;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Regular.woff2), url(../font/Gilroy/Gilroy-Regular.woff);
}

@font-face {
  font-weight: 500;
  font-family: Gilroy;
  font-style: normal;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Medium.woff2), url(../font/Gilroy/Gilroy-Medium.woff);
}

@font-face {
  font-weight: 600;
  font-family: Gilroy;
  font-style: normal;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-SemiBold.woff22), url(../font/Gilroy/Gilroy-SemiBold.woff);
}

@font-face {
  font-weight: 700;
  font-family: Gilroy;
  font-style: normal;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-Bold.woff2), url(../font/Gilroy/Gilroy-Bold.woff);
}

@font-face {
  font-weight: 700;
  font-family: Gilroy;
  font-style: italic;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-BoldItalic.woff2), url(../font/Gilroy/Gilroy-BoldItalic.woff);
}

@font-face {
  font-weight: 800;
  font-family: Gilroy;
  font-style: normal;
  font-display: swap;
  src: url(../font/Gilroy/Gilroy-ExtraBold.woff), url(../font/Gilroy/Gilroy-ExtraBold.woff);
}

.btn,
.header__top::after,
.title-line::after,
.social__link,
.social__link:hover,
.nav::after,
.programs__btn-text::after,
.slider-pagination .swiper-pagination-bullet,
.contacts__link,
.contacts__link:hover,
.footer::before,
.footer__copyright::after {
  background: linear-gradient(306.69deg, #cb8a1b -29.86%, #dda440 30.34%, #e9c005 66.18%, #e9c104 100%);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px !important;
}

.col {
  margin: 0 15px;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 20px;
  font-family: "Gilroy", sans-serif;
  color: #000;
  background-color: #000;
}

body.fixed {
  overflow: hidden;
}

.main {
  flex-grow: 1;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

.main-wrapper::before,
.main-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  background-size: 100%;
}

.main-wrapper::before {
  top: -323px;
  left: 0;
  width: 623px;
  height: 1250px;
  background: url("../img/bc/tinsel-left.png") no-repeat;
}

.main-wrapper::after {
  top: -510px;
  right: 0;
  width: 603px;
  height: 1506px;
  background: url("../img/bc/tinsel-right.png") no-repeat;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

:-webkit-autofill {
  box-shadow: 0 0 100px #fff inset;
  -webkit-text-fill-color: currentColor;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding-right: 30px;
  padding-left: 30px;
}

.container--800 {
  max-width: 860px;
}

.btn-reset {
  padding: 0;
  background-color: transparent;
  border: none;
}

.btn {
  min-width: 300px;
  padding: 8px 80px;
  font-weight: 400;
  font-size: 33px;
  line-height: 63px;
  font-family: "Gilroy", sans-serif;
  color: #000;
  font-style: normal;
  border-radius: 60px;
}

.btn-box {
  position: absolute;
  top: 50%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-next,
.btn-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: url("../img/icon/arrow-left.png") no-repeat;
  cursor: pointer;
}

.btn-next:hover,
.btn-prev:hover {
  opacity: .5;
}

.btn-next.swiper-button-disabled,
.btn-prev.swiper-button-disabled {
  opacity: .5;
}

.btn-next {
  transform: rotate(-180deg);
}

.btn-prev {
  margin-left: -60px;
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header {
  position: relative;
  background: url("../img/bc/header-bc.png") no-repeat;
  background-size: cover;
}

.header__top-container {
  display: flex;
  max-width: 1380px;
  min-height: 655px;
  padding-top: 50px;
}

.header__top {
  position: relative;
}

.header__top::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 20;
  display: block;
  width: 100%;
  height: 4px;
}

.header__wrap-logo {
  margin-top: -50px;
  margin-left: -20px;
}

.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}

.header__social {
  margin-bottom: 20px;
}

.header__phone-adress {
  display: flex;
  flex-direction: column;
}

.header__img-bc {
  position: absolute;
  right: 115px;
  bottom: 15px;
  z-index: 10;
}

.header__img-bc img {
  filter: drop-shadow(0px 3.71328px 92.8321px rgba(255, 255, 255, 0.25)) drop-shadow(4.64161px 3.71328px 46.4161px rgba(197, 151, 6, 0.5));
}

.header__bottom {
  position: relative;
  min-height: 118px;
  margin-top: -16px;
  background-color: #000;
}

.header__bottom-container {
  max-width: 1380px;
  padding: 50px 30px 0;
}

.header__burger {
  display: none;
}

.header__btn-close {
  display: none;
}

.breadcrumb {
  max-width: 1380px;
}

.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  margin: 55px 0 85px;
}

.breadcrumb__item {
  position: relative;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  white-space: nowrap;
}

.breadcrumb__item:not(:last-child) {
  margin-right: 35px;
}

.breadcrumb__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -25px;
  display: block;
  width: 15px;
  height: 10px;
  background: url("../img/icon/arrow-right.svg") center center no-repeat;
  background-size: 100%;
  transform: translate(0, -50%);
}

.breadcrumb__link {
  color: inherit;
}

.breadcrumb__link:hover {
  color: #dda440cc;
  text-decoration: none;
}

h1 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 65px;
  line-height: 75px;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #fff;
  font-style: normal;
}

h2 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  font-family: "Gilroy", sans-serif;
  text-align: left;
  color: #fff;
  font-style: normal;
}

h3 {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
}

.title-line {
  position: relative;
  margin-bottom: 40px;
}

.title-line::after {
  content: "";
  bottom: 0;
  display: block;
  max-width: 800px;
  height: 4px;
  margin: 0 auto;
  border-radius: 7px;
}

.text {
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25;
  font-family: "Gilroy", sans-serif;
  color: #fff;
}

.text--bold {
  font-weight: 700;
}

.text--center {
  text-align: center;
}

.text--mg {
  margin-top: 80px;
  margin-bottom: 120px;
}

.text a:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}

input,
textarea {
  max-width: 300px;
  padding: 18px 30px 18px 45px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #828282;
  font-style: normal;
  letter-spacing: .02em;
  background-color: #fff;
  border: none;
  border-radius: 30px;
  outline: none;
}

textarea {
  min-width: 648px;
  max-height: 205px;
  padding-top: 35px;
}

.form__block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.social {
  display: flex;
  justify-content: space-between;
  min-width: 217px;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  transition: .2s;
}

.social__link svg {
  width: 23px;
  height: 23px;
  fill: #fff;
}

.phone-adress__text {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  font-family: "Gilroy", sans-serif;
  text-align: right;
  color: #fff;
  letter-spacing: .03em;
  transition: .2s;
}

.phone-adress__text:last-child {
  font-weight: 400;
}

.phone-adress__text:first-child:hover {
  color: #dda440cc;
  text-decoration: none;
}

.nav {
  position: relative;
}

.nav::after {
  content: "";
  bottom: 0;
  display: block;
  max-width: 1100px;
  height: 4px;
  margin: 0 auto;
  border-radius: 7px;
}

.nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.nav__item {
  margin-bottom: 20px;
  transition: .2s;
}

.nav__item:not(:last-child) {
  margin-right: 39px;
}

.nav__link {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  letter-spacing: .02em;
  white-space: nowrap;
  transition: .3s;
}

.nav__link:hover {
  color: #dda440cc;
  text-decoration: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;
  background: linear-gradient(220.08deg, #aa0901 -4.32%, #d10c01 92.32%);
  border-radius: 26px;
  transition: .3s;
}

.card::after,
.card::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 35px;
}

.card::after {
  bottom: 0;
  left: 0;
  background: url("../img/cards/ellipse-bottom.png") no-repeat;
  background-size: cover;
}

.card::before {
  top: 0;
  left: 0;
  background: url("../img/cards/ellipse-top.png") no-repeat;
  background-size: cover;
}

.card:hover {
  text-decoration: none;
  filter: drop-shadow(2px 2px 20px rgba(255, 185, 29, 0.8));
}

.card__dot-right,
.card__dot-left {
  position: absolute;
  top: 15px;
  display: block;
  width: 24px;
  height: calc(100% - 15px - 38px);
  background: url("../img/cards/ellipse.png") repeat-y;
}

.card__dot-left {
  left: 0;
}

.card__dot-right {
  right: 0;
}

.card__wrap-img {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

.card__desc {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-shadow: 0 3.25647px 3.25647px rgba(0, 0, 0, 0.25);
}

.card__title {
  max-width: 235px;
  min-height: 55px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 26px;
  line-height: 1;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #fff;
}

.card__desc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__desc-text {
  position: relative;
  padding-left: 34px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
}

.card__desc-text--time {
  margin-bottom: 12px;
}

.card__desc-text--time:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/icon/time.svg") center center no-repeat;
  background-size: 100%;
}

.card__desc-text--age {
  margin-bottom: 10px;
}

.card__desc-text--age:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  background: url("../img/icon/baby.svg") center center no-repeat;
  background-size: 100%;
}

.card__desc-text br {
  display: none;
}

.card__desc-cost {
  font-weight: 800;
  font-size: 28px;
  line-height: 1.1;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
}

.cards {
  padding: 160px 0 90px;
}

.cards__container {
  max-width: 1145px;
}

.cards__title {
  margin-bottom: 90px;
}

.cards__wrap-btn {
  display: flex;
  justify-content: center;
}

.cards-slider {
  margin-bottom: 90px;
  padding: 30px;
}

.animals {
  position: relative;
  padding-top: 112px;
  padding-bottom: 160px;
}

.animals::after,
.animals::before {
  content: "";
  position: absolute;
  display: block;
}

.animals::before {
  top: -92px;
  left: 0;
  width: 766px;
  height: 1357px;
  background: url("../img/bc/ann-left.png") no-repeat;
}

.animals::after {
  top: 209px;
  right: 0;
  width: 556px;
  height: 959px;
  background: url("../img/bc/ann-right.png") no-repeat;
}

.animals__container {
  position: relative;
  z-index: 10;
}

.animals__title {
  padding-bottom: 10px;
}

.animals__wrap-text {
  margin-bottom: 40px;
}

.animals__text:nth-child(2) {
  margin-bottom: 40px;
}

.animals__text.text--mg {
  margin-bottom: 115px;
}

.animals__wrap-video {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.animals__wrap-video iframe {
  border: none;
}

.animals__list {
  margin: 20px 0 25px;
}

.animals__item {
  position: relative;
  padding-left: 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25;
  font-family: "Gilroy", sans-serif;
  color: #fff;
}

.animals__item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  display: block;
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, 0);
}

.animals__wrap-btn {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.animals__wrap-video + .animals__wrap-text .animals__text:nth-child(2) {
  margin-bottom: 25px;
}

.tinsel__left {
  position: absolute;
  top: 1085px;
  left: 0;
  width: 623px;
  height: 100%;
  background: url("../img/bc/tinsel-left.png") no-repeat;
}

.tinsel__right {
  position: absolute;
  right: 0;
  bottom: -41%;
  display: block;
  width: 603px;
  height: 100%;
  background: url("../img/bc/tinsel-right.png") no-repeat;
  background-size: 100%;
}

.about-us {
  position: relative;
  padding-bottom: 220px;
}

.about-us__title {
  margin-bottom: 120px;
}

.about-us__wrap-text {
  position: relative;
  z-index: 15;
}

.about-us__wrap-img {
  position: absolute;
  bottom: -80px;
  left: 0;
  z-index: 10;
}

.about-us__wrap-img img {
  min-width: 530px;
}

.about-us__wrap-btn {
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: center;
}

.cooperation {
  padding-bottom: 130px;
}

.cooperation__title {
  margin-bottom: 120px;
}

.cooperation__wrap-text {
  margin-bottom: 120px;
}

.cooperation__list {
  margin: 120px 0;
}

.cooperation__title-list {
  padding-bottom: 15px;
}

.cooperation__video-list {
  position: relative;
  z-index: 10;
}

.cooperation__video-item {
  margin-bottom: 20px;
}

.cooperation__video-link {
  font-weight: 800;
  font-size: 20px;
  line-height: 1.25;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  transition: .3s;
}

.cooperation__video-link:hover {
  color: #dda440cc;
  text-decoration: none;
}

.cooperation__video-link span {
  display: block;
}

.cooperation__text.text--center {
  margin-top: 120px;
}

.cooperation__tinsel .tinsel__right {
  bottom: -47%;
}

.present-list__item {
  position: relative;
  margin-bottom: 60px;
  padding-left: 55px;
}

.present-list__item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 32px;
  height: 44px;
  background: url("../img/icon/file.svg") no-repeat;
  background-size: 100%;
  transform: translate(0, -50%);
}

.present-list__link {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
  transition: .3s;
}

.present-list__link span {
  display: block;
}

.present-list__link:hover {
  color: #dda440cc;
  text-decoration: none;
}

.programs {
  position: relative;
  z-index: 10;
  margin-bottom: 75px;
  padding: 0 0 90px;
}

.programs__container {
  max-width: 1080px;
}

.programs__title {
  margin-bottom: 135px;
}

.programs__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.programs__filter-option {
  min-width: 305px;
}

.programs__container-inner {
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px 60px !important;
}

.programs__card {
  width: calc(100% / 3 - 60px);
  margin: 0 30px 60px;
}

.programs__tinsel .tinsel__right {
  bottom: -68%;
}

.programs__wrap-btn {
  display: none;
}

.programs__btn {
  position: relative;
  padding: 5px 15px;
  text-align: center;
  background: #000;
  border-radius: 30px;
}

.programs__btn::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
  background: linear-gradient(306.69deg, #cb8a1b -29.86%, #dda440 30.34%, #e9c005 66.18%, #e9c104 100%);
  border-radius: 30px;
}

.programs__btn.hide-js {
  display: none;
}

.programs__btn-text::after {
  content: "Показать ещё";
  display: block;
  font-weight: 400;
  font-size: 23.5px;
  line-height: 45px;
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search__input {
  padding: 18px 30px 18px 45px;
  font-weight: 400;
}

.filter {
  position: relative;
  display: flex;
  flex-direction: column;
}

.filter__btn {
  position: relative;
  align-self: flex-end;
  max-width: 175px;
  margin-bottom: 20px;
  padding-left: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
}

.filter__btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 24px;
  background: url("../img/icon/arr-toggle.png") no-repeat;
  background-size: 100%;
}

.filter__variation-wrap {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 15;
  display: flex;
  flex-direction: column;
  padding: 37px 60px;
  background-color: #fff;
  border-radius: 30px;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
}

.filter__variation-wrap.active {
  visibility: visible;
  opacity: 1;
}

.filter__variation {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #828282;
  font-style: normal;
  letter-spacing: .02em;
  cursor: pointer;
}

.filter__variation:not(:last-child) {
  margin-bottom: 10px;
}

.filter__variation:hover,
.filter__variation:active {
  font-weight: 800;
  border-bottom: 1px solid #828282;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__arrow-prev,
.pagination__arrow-next {
  display: block;
  width: 30px;
  height: 30px;
  background: url("../img/icon/arrow-left.png") no-repeat;
  background-size: 100%;
}

.pagination__arrow-prev:hover,
.pagination__arrow-next:hover {
  opacity: .5;
}

.pagination__arrow-prev {
  margin-right: 30px;
}

.pagination__arrow-next {
  margin-left: 30px;
  transform: rotate(180deg);
}

.pagination__links {
  display: flex;
  justify-content: space-between;
  min-width: 265px;
}

.pagination__page {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
  transition: .3s;
}

.pagination__page:hover {
  color: #dda440cc;
  text-decoration: none;
}

.pagination__page--current {
  color: #dda440cc;
}

.programs-desc {
  position: relative;
  padding-bottom: 150px;
}

.programs-desc::after {
  content: "";
  position: absolute;
  top: 135px;
  left: 0;
  display: block;
  width: 623px;
  height: 100%;
  background: url("../img/bc/tinsel-left.png") no-repeat;
}

.programs-desc__container {
  z-index: 10;
}

.programs-desc__title {
  margin-bottom: 10px;
}

.programs-desc__list {
  margin: 120px 0;
}

.programs-desc__wrap-btn {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.single {
  position: relative;
  margin-bottom: 165px;
}

.single__title {
  margin-bottom: 120px;
}

.single__title br {
  display: none;
}

.single__wrap-video {
  position: relative;
  max-width: 1022px;
  margin: 0 auto 120px;
}

.single__wrap-text {
  margin-bottom: 120px;
}

.single__text a.text {
  display: block;
}

.single__list {
  margin-bottom: 40px;
  padding-left: 30px;
  list-style-type: disc;
}

.single__item {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
}

.single__wrap-image {
  position: relative;
  max-width: 1080px;
  margin: 0 auto 120px;
}

.single__wrap-btn {
  display: flex;
  justify-content: center;
}

.single__tinsel .tinsel__right {
  bottom: -52.5%;
}

.video-slider__btn-prev {
  margin-left: -90px;
}

.video-slider__btn-next {
  margin-right: -90px;
}

.video-slider__pagination.slider-pagination {
  display: none;
}

.image-slider__wrap-img {
  display: flex;
  justify-content: center;
  margin: 0 30px;
}

.image-slider__pagination.slider-pagination {
  display: none;
}

.image-slider__btn-next {
  margin-right: -60px;
}

.like {
  position: relative;
  padding-bottom: 50px;
}

.like__container {
  max-width: 1145px;
}

.like__title {
  margin-bottom: 50px;
  text-align: center;
}

.like__title br {
  display: none;
}

.like__wrap-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 160px;
}

.like__tinsel .tinsel__left {
  top: -285px;
}

.like__tinsel .tinsel__right {
  bottom: -32.5%;
}

.like__slider-box-btn {
  top: 43%;
}

.like__slider-btn-next.btn-next {
  margin-right: 25px;
}

.reviews-slider {
  margin-top: -50px;
  margin-bottom: 145px;
}

.reviews-slider__title {
  margin-bottom: 52px;
  text-align: center;
}

.reviews-slider__wrapper {
  margin-bottom: 113px;
  padding-top: 30px;
}

.reviews-slider__slide.swiper-slide {
  padding: 10px;
  transform: scale(1);
  transition: .2s;
}

.reviews-slider__slide.swiper-slide-active {
  position: relative;
  z-index: 100;
  transform: scale(1.2);
}

.reviews-slider__wrap-img {
  border-radius: 8px;
  box-shadow: 1px 2px 5px rgba(221, 164, 64, 0.85);
}

.reviews-slider__wrap-img img {
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.slider-pagination {
  position: absolute;
  display: flex;
  justify-content: center;
}

.slider-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 17px;
}

.slider-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: .5;
}

.slider-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px;
}

.slider-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

.partners {
  padding-bottom: 150px;
}

.partners__title {
  margin-bottom: 120px;
}

.partners__wrap-text {
  margin-bottom: 75px;
}

.partners__wrap-block {
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 50px;
}

.partners__block {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 205px;
  margin-bottom: 70px;
  padding: 20px;
  background-color: #fff;
  border-radius: 26px;
  transition: .3s;
}

.partners__block:hover {
  text-decoration: none;
  box-shadow: 2px 2px 44px rgba(255, 185, 29, 0.8);
}

.partners__wrap-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
}

.partners__wrap-img img {
  max-height: 100%;
  margin-bottom: 10px;
}

.partners__block-title {
  min-height: 44px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #333;
  font-style: normal;
}

.partners__contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners__contacts a {
  color: #fff;
}

.partners__contacts a:hover {
  color: #fff;
  text-decoration: none;
}

.partners__contacts .partners__text {
  margin-bottom: 15px;
}

.media__container {
  position: relative;
  z-index: 10;
}

.media__title {
  margin-bottom: 120px;
}

.media__text a {
  color: #fff;
}

.media__text br {
  display: none;
}

.media__wrap-text {
  position: relative;
  z-index: 10;
  margin-bottom: 120px;
}

.media__wrap-video {
  position: relative;
  max-width: 1022px;
  margin: 0 auto 160px;
}

.media__wrap-image {
  position: relative;
  max-width: 1080px;
  margin: 0 auto 120px;
}

.media__wrap-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 210px;
}

.media__tinsel .tinsel__right {
  bottom: -54%;
}

.contacts {
  padding-bottom: 160px;
}

.contacts__container {
  max-width: 1075px;
}

.contacts__title {
  margin-bottom: 110px;
}

.contacts__block {
  display: flex;
  flex-direction: column;
}

.contacts__phone {
  position: relative;
  margin-bottom: 50px;
  padding-left: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
  transition: .3s;
}

.contacts__phone:hover {
  color: #dda440cc;
  text-decoration: none;
}

.contacts__phone::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 38px;
  height: 38px;
  background: url("../img/icon/phone.png") no-repeat;
  background-size: 100%;
  transform: translate(0, -50%);
}

.contacts__link-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.contacts__link-wrap:hover {
  text-decoration: none;
}

.contacts__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  transition: .3s;
}

.contacts__link svg {
  width: 23px;
  height: 23px;
  fill: #fff;
}

.contacts__info,
.contacts__address,
.contacts__time {
  padding-left: 15px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
}

.contacts__info {
  transition: .3s;
}

.contacts__info:hover {
  color: #dda440cc;
}

.contacts__address {
  margin-bottom: 20px;
  padding-left: 0;
}

.contacts__time {
  margin-bottom: 45px;
  padding-left: 0;
}

.contacts__map {
  height: 376px;
  margin-bottom: 120px;
}

.contacts__map iframe {
  width: 100%;
  height: 100%;
}

.contacts__form-wrap {
  max-width: 648px;
  margin: 0 auto;
}

.contacts__form-title {
  margin-bottom: 30px;
}

.contacts__textarea {
  margin-bottom: 75px;
}

.contacts__wrap-btn {
  display: flex;
  justify-content: center;
}

.reviews {
  padding-bottom: 140px;
}

.reviews__container {
  position: relative;
  z-index: 10;
  max-width: 1080px;
}

.reviews__title {
  margin-bottom: 120px;
}

.reviews__wrap-blocks {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -60px !important;
}

.reviews__block {
  width: calc(100% / 3 - 60px);
  margin: 0 30px;
  margin-bottom: 120px;
}

.reviews__wrap-img {
  margin-bottom: 30px;
}

.reviews__wrap-img img {
  width: 100%;
}

.reviews__date {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  letter-spacing: .02em;
  background: linear-gradient(306.69deg, #cb8a1b -29.86%, #dda440 30.34%, #e9c005 66.18%, #e9c104 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviews__pagination {
  margin-bottom: 120px;
}

.reviews__form-wrap {
  max-width: 648px;
  margin: 0 auto;
}

.reviews__form-title {
  margin-bottom: 60px;
  text-align: center;
}

.reviews__textarea {
  margin: 40px 0 60px;
}

.reviews__contacts-block {
  margin-bottom: 60px;
}

.reviews__contacts-title {
  margin-bottom: 30px;
}

.reviews__contacts-block .form__block {
  flex-wrap: wrap;
}

.reviews__contacts-block input {
  margin-bottom: 30px;
}

.reviews__know-title {
  margin-bottom: 30px;
}

.reviews__know-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.reviews__know-btn {
  min-width: 300px;
  margin-bottom: 36px;
  padding: 17px 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
  border: 1px solid #fff;
  border-radius: 30px;
  transition: .3s;
}

.reviews__know-btn:hover {
  color: #dda440cc;
  border-color: #dda440cc;
}

.reviews__wrap-btn {
  display: flex;
  justify-content: center;
}

.footer {
  position: relative;
  padding-bottom: 100px;
  background-color: #000;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 4px;
  border-radius: 7px;
}

.footer__container {
  max-width: 1380px;
  padding: 40px 30px 22px;
}

.footer__top {
  margin-bottom: 50px;
}

.footer__nav-md {
  display: none;
}

.footer__nav-md .nav__list {
  align-items: center;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
}

.footer__social {
  min-width: 265px;
  margin-top: 15px;
  margin-left: 50px;
}

.footer__phone-adress {
  display: flex;
  flex-direction: column;
}

.footer__copyright {
  display: flex;
  justify-content: center;
}

.footer__copy-text {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  font-family: "Gilroy", sans-serif;
  text-align: center;
  color: #fff;
  font-style: normal;
  letter-spacing: .02em;
}

.footer__copy-text br {
  display: none;
}

@media (max-width: 1376px) {
  .nav__item:not(:last-child) {
    margin-right: 55px;
  }
}

@media (max-width: 1199.98px) {
  .btn-next {
    margin-right: 60px;
  }

  .btn-prev {
    margin-left: 0;
  }

  .card {
    padding-bottom: 30px;
  }

  .cards-slider {
    max-width: 690px;
    margin-bottom: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .programs__title {
    margin-bottom: 105px;
  }

  .programs__filter {
    max-width: 690px;
    margin: 0 auto 10px;
    padding: 0 15px;
  }

  .programs__wrapper {
    max-width: 690px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .programs__card {
    width: calc(100% / 2 - 60px);
  }

  .pagination__links {
    min-width: 215px;
  }

  .video-slider__btn-next {
    margin-right: 0;
  }

  .image-slider__btn-next {
    margin-right: 0;
  }

  .reviews__container {
    max-width: 690px;
  }

  .reviews__block {
    width: calc(100% / 2 - 60px);
  }
}

@media (max-width: 1024.98px) {
  .main-wrapper::before {
    top: -360px;
    background: url("../img/bc/tinsel-left-tab.png") no-repeat;
  }

  .main-wrapper::after {
    top: -545px;
    width: 395px;
    background: url("../img/bc/tinsel-right-tab.png") no-repeat;
  }

  .container {
    max-width: 930px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .container--800 {
    max-width: 830px;
  }

  .btn-next {
    margin-right: 30px;
  }

  .header__top::after {
    bottom: 10px;
  }

  .header__img-bc img {
    max-height: 584px;
  }

  .header__img-bc {
    right: 65px;
    bottom: 10px;
  }

  .header__bottom {
    margin-top: -10px;
  }

  .header__bottom-container {
    max-width: 960px;
    padding-top: 35px;
  }

  .breadcrumb__list {
    margin-bottom: 50px;
  }

  h3 {
    font-size: 20px;
  }

  .nav__item:not(:last-child) {
    margin-right: 68px;
  }

  .cards {
    padding-top: 125px;
  }

  .cards-slider {
    max-width: 690px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .animals::before {
    top: 15px;
    background: url("../img/bc/tinsel-left-tab.png") no-repeat;
  }

  .animals::after {
    background: none;
  }

  .tinsel__left {
    width: 415px;
    background: url("../img/bc/tinsel-left-tab.png") no-repeat;
  }

  .tinsel__right {
    width: 395px;
    background: url("../img/bc/tinsel-right-tab.png") no-repeat;
  }

  .about-us {
    padding-bottom: 180px;
  }

  .about-us__wrap-img img {
    object-fit: cover;
  }

  .about-us__wrap-img {
    bottom: -80px;
    left: 48%;
  }

  .single__wrap-video {
    max-width: 800px;
  }

  .single__wrap-image {
    max-width: 750px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .single__tinsel .tinsel__right {
    bottom: -70.5%;
  }

  .video-slider__box-btn {
    top: 50%;
  }

  .video-slider__btn-prev {
    margin-left: -50px;
  }

  .video-slider__btn-next {
    margin-right: -50px;
  }

  .image-slider__btn-next {
    margin-right: 10px;
  }

  .image-slider__btn-prev {
    margin-left: -10px;
  }

  .media__wrap-video {
    max-width: 800px;
  }

  .media__wrap-image {
    max-width: 750px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .contacts__container {
    max-width: 830px;
  }

  .reviews__container {
    max-width: 690px;
  }

  .reviews__title {
    margin-bottom: 40px;
  }

  .reviews__textarea {
    margin-bottom: 80px;
  }

  .reviews__textarea {
    margin: 25px 0 30px;
  }

  .footer__top {
    margin-bottom: 80px;
  }

  .footer__bottom {
    margin-bottom: 85px;
  }

  .footer__social {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .video-slider__btn-prev {
    margin-left: 0;
  }

  .video-slider__btn-next {
    margin-right: 0;
  }
}

@media (max-width: 870px) {
  .nav__item:not(:last-child) {
    margin-right: 40px;
  }
}

@media (max-width: 767.98px) {
  .main-wrapper::before {
    top: -67px;
    width: 130px;
    height: 393px;
    background: url("../img/bc/tinsel-left-mob.png") no-repeat;
  }

  .main-wrapper::after {
    top: -125px;
    width: 125px;
    height: 393px;
    background: url("../img/bc/tinsel-right-mob.png") no-repeat;
  }

  .container {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .container--800 {
    max-width: 690px;
    padding: 0 15px;
  }

  .btn {
    min-width: 215px;
    padding: 6px 57px;
    font-size: 23.5px;
    line-height: 45px;
  }

  .btn-next,
  .btn-prev {
    width: 20px;
    height: 20px;
    background: url("../img/icon/arrow-left-mob.png") no-repeat;
    background-size: 100%;
  }

  .header__top-container {
    min-height: 405px;
    padding-top: 0;
    overflow-x: hidden;
  }

  .header__wrap-logo {
    margin: 0;
  }

  .header__contacts {
    display: none;
  }

  .header__img-bc img {
    max-height: 346px;
  }

  .header__img-bc {
    right: -30px;
    bottom: 15px;
  }

  .header__bottom {
    min-height: 0;
  }

  .header__bottom-container {
    padding-top: 0;
  }

  .header__nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    width: 226px;
    height: 100%;
    margin-left: 0;
    padding: 20px;
    padding-top: 40px;
    background-color: #262626;
    transform: translate(-100%, 0);
    transition: .3s;
  }

  .header__nav--active {
    transform: translate(0, 0);
  }

  .header__burger {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 50;
    display: block;
    width: 50px;
    height: 50px;
    background: url(../img/icon/burger.svg) center center no-repeat;
  }

  .header__btn-close {
    position: absolute;
    top: 30px;
    right: 12px;
    z-index: 100;
    display: block;
    width: 30px;
    height: 30px;
    background: url(../img/icon/close.svg) center center no-repeat;
  }

  .breadcrumb__list {
    margin: 40px 0;
  }

  .breadcrumb__item {
    font-weight: 400;
    font-size: 12px;
  }

  .breadcrumb__item:not(:last-child) {
    margin-right: 18px;
  }

  .breadcrumb__item:not(:last-child)::after {
    right: -15px;
    width: 8px;
    height: 5px;
  }

  h1 {
    font-size: 32px;
    line-height: 33px;
  }

  h2 {
    font-size: 20px;
    line-height: 25px;
  }

  h3 {
    font-size: 12px;
  }

  .title-line {
    margin-bottom: 15px;
  }

  .text--mg {
    margin-top: 30px;
  }

  .text {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
  }

  input,
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 12px 29px;
    font-size: 14px;
  }

  textarea {
    min-width: auto;
    max-height: 115px;
    border-radius: 20px;
  }

  .form__block {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .social {
    justify-content: center;
  }

  .social__link svg {
    width: 17px;
    height: 17px;
  }

  .social__link {
    width: 28px;
    height: 28px;
  }

  .phone-adress__text {
    font-size: 16px;
  }

  .nav::after {
    display: none;
  }

  .nav__list {
    flex-direction: column;
  }

  .nav__link {
    font-size: 16px;
  }

  .card::after {
    background: url("../img/cards/ellipse-bottom-mob.png") no-repeat;
  }

  .card::before {
    background: url("../img/cards/ellipse-top-mob.png") no-repeat;
  }

  .card {
    padding: 0 15px 20px;
  }

  .card__dot-right,
  .card__dot-left {
    top: 30.5px;
    width: 12px;
    height: calc(100% - 30px - 35px);
    background: url("../img/cards/ellipse-mob.png") repeat-y;
  }

  .card__dot-right {
    right: 4px;
  }

  .card__wrap-img {
    margin: 15px 0;
  }

  .card__title {
    min-height: 35px;
    font-size: 16px;
  }

  .card__desc-text {
    padding-left: 28px;
    font-size: 12px;
  }

  .card__desc-text--time {
    margin-bottom: 8px;
  }

  .card__desc-text--time:before {
    width: 15px;
    height: 15px;
    background-size: 100%;
  }

  .card__desc-text--age:before {
    width: 18px;
    height: 14px;
    background-size: 100%;
  }

  .card__desc-text br {
    display: block;
  }

  .card__desc-cost {
    font-size: 20px;
  }

  .cards {
    margin-top: -1px;
    padding-top: 55px;
    padding-bottom: 0;
  }

  .cards__title {
    margin-bottom: 10px;
  }

  .cards-slider {
    max-width: 225px;
    margin-bottom: 30px;
  }

  .animals {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  .animals::before {
    top: -217px;
    width: 130px;
    height: 393px;
    background: url("../img/bc/tinsel-left-mob.png") no-repeat;
  }

  .animals__text.text--mg {
    margin-bottom: 0;
  }

  .animals__wrap-video {
    margin-right: -35px;
    margin-left: -35px;
  }

  .animals__wrap-video iframe {
    width: 323px;
    height: 182px;
  }

  .animals__item {
    font-size: 12px;
    line-height: 15px;
  }

  .animals__btn {
    min-width: 286px;
    padding: 5px 30px;
  }

  .tinsel__left {
    width: 130px;
    background: url("../img/bc/tinsel-left-mob.png") no-repeat;
  }

  .tinsel__right {
    bottom: -80%;
    width: 125px;
    background: url("../img/bc/tinsel-right-mob.png") no-repeat;
  }

  .about-us__title {
    margin-bottom: 40px;
  }

  .about-us__wrap-img img {
    min-width: 450px;
  }

  .about-us__wrap-img {
    left: 35%;
  }

  .cooperation {
    padding-bottom: 60px;
  }

  .cooperation__title {
    margin-bottom: 40px;
  }

  .cooperation__wrap-text {
    margin-bottom: 50px;
  }

  .cooperation__list {
    margin: 35px 0;
  }

  .cooperation__title-line {
    margin-bottom: 5px;
  }

  .cooperation__video-item {
    margin-bottom: -2px;
  }

  .cooperation__video-link span {
    font-weight: 400;
  }

  .cooperation__video-link {
    font-size: 12px;
  }

  .cooperation__text.text--center {
    margin: 55px -10px 0;
  }

  .present-list__item::after {
    width: 20px;
    height: 27px;
  }

  .present-list__item {
    margin-bottom: 30px;
    padding-left: 35px;
  }

  .present-list__link {
    font-size: 12px;
  }

  .present-list__link br {
    display: none;
  }

  .programs {
    margin-bottom: 10px;
  }

  .programs__title {
    margin-bottom: 65px;
  }

  .programs__filter {
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }

  .programs__filter-search {
    width: 100%;
    margin-bottom: 35px;
  }

  .programs__wrapper {
    max-width: 225px;
  }

  .programs__container-inner {
    margin: 0 -30px 20px !important;
  }

  .programs__card {
    width: calc(100% / 1 - 60px);
    margin-bottom: 40px;
  }

  .programs__card:nth-child(n + 8) {
    display: none;
  }

  .programs__card.active-js:nth-child(n + 8) {
    display: block;
  }

  .programs__wrap-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }

  .search__input {
    width: 100%;
    padding: 12px 29px;
    font-size: 14px;
  }

  .filter__btn {
    align-self: center;
    font-size: 12px;
  }

  .filter__btn::before {
    width: 16px;
    height: 20px;
  }

  .filter__variation-wrap {
    top: 35px;
    width: 100%;
    padding: 13px 85px;
    border-radius: 20px;
  }

  .filter__variation {
    font-size: 14px;
    text-align: center;
  }

  .pagination {
    max-width: 300px;
    margin: 0 auto;
  }

  .pagination__arrow-prev,
  .pagination__arrow-next {
    width: 100%;
    height: 20px;
    background: url("../img/icon/arrow-left-mob.png") no-repeat;
  }

  .pagination__arrow-prev {
    margin-right: 20px;
  }

  .pagination__arrow-next {
    margin-left: 20px;
  }

  .pagination__page {
    font-size: 12px;
  }

  .programs-desc {
    padding-bottom: 60px;
  }

  .programs-desc::after {
    top: -15px;
    background: url("../img/bc/tinsel-left-mob.png") no-repeat;
  }

  .programs-desc__list {
    margin: 35px 0;
  }

  .programs-desc__wrap-btn {
    margin-top: 40px;
  }

  .single {
    margin-bottom: 100px;
  }

  .single__title {
    margin-bottom: 40px;
  }

  .single__title br {
    display: block;
  }

  .single__wrap-video {
    margin-bottom: 110px;
  }

  .single__wrap-text {
    margin-bottom: 60px;
  }

  .single__item {
    font-size: 12px;
  }

  .single__wrap-image {
    max-width: 100%;
    margin-bottom: 110px;
    padding: 0;
  }

  .single__wrap-btn {
    position: relative;
    z-index: 10;
  }

  .video-slider__box-btn {
    display: none;
  }

  .video-slider__pagination.slider-pagination {
    display: flex;
  }

  .video-slider__pagination.slider-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -50px;
  }

  .image-slider__wrap-img {
    margin: 0;
  }

  .image-slider__pagination.slider-pagination {
    display: flex;
  }

  .image-slider__pagination.slider-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -50px;
  }

  .image-slider__box-btn {
    display: none;
  }

  .like__title {
    margin-bottom: 10px;
  }

  .like__title br {
    display: block;
  }

  .like__wrap-btn {
    position: relative;
    z-index: 10;
    margin-bottom: 100px;
  }

  .like__slider-box-btn {
    top: 48%;
  }

  .like__slider.cards-slider {
    margin-bottom: 10px;
  }

  .reviews-slider {
    margin-bottom: 50px;
  }

  .reviews-slider__title {
    margin-bottom: 30px;
  }

  .reviews-slider__slide.swiper-slide {
    padding: 0;
  }

  .slider-pagination .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
  }

  .partners {
    padding-bottom: 40px;
  }

  .partners__title {
    margin-bottom: 40px;
  }

  .partners__wrap-text {
    margin-bottom: 40px;
  }

  .partners__block {
    justify-content: space-between;
    width: 250px;
    min-height: 130px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  .partners__wrap-img {
    height: 64px;
  }

  .partners__block-title {
    min-height: auto;
    font-size: 13px;
  }

  .media__title {
    margin-bottom: 40px;
  }

  .media__text br {
    display: block;
  }

  .media__wrap-text {
    margin-bottom: 35px;
  }

  .media__wrap-video {
    margin-bottom: 150px;
  }

  .media__wrap-image {
    max-width: 100%;
    margin-bottom: 110px;
    padding: 0;
  }

  .media__wrap-btn {
    margin-bottom: 150px;
  }

  .contacts {
    padding-bottom: 55px;
  }

  .contacts__title {
    margin-bottom: 45px;
  }

  .contacts__block {
    padding-left: 25px;
  }

  .contacts__phone {
    margin-bottom: 20px;
    padding-left: 35px;
    font-size: 12px;
  }

  .contacts__phone::after {
    width: 20px;
    height: 20px;
  }

  .contacts__link-wrap {
    margin-bottom: 40px;
  }

  .contacts__link svg {
    width: 13px;
    height: 13px;
  }

  .contacts__link {
    width: 20px;
    height: 20px;
  }

  .contacts__info,
  .contacts__address,
  .contacts__time {
    font-size: 12px;
  }

  .contacts__address {
    margin-bottom: 0;
  }

  .contacts__map {
    margin-bottom: 60px;
  }

  .contacts__form-title {
    margin-bottom: 35px;
    text-align: center;
  }

  .contacts__textarea {
    margin-bottom: 35px;
  }

  .contacts__input:not(:last-child) {
    margin-bottom: 20px;
  }

  .reviews {
    padding-bottom: 60px;
  }

  .reviews__container {
    max-width: 540px;
  }

  .reviews__wrap-blocks {
    justify-content: center;
    margin: 0 !important;
  }

  .reviews__block {
    width: 100%;
    max-width: 300px;
    margin: 0 0 40px;
  }

  .reviews__wrap-img {
    margin-bottom: 15px;
  }

  .reviews__date {
    font-size: 12px;
  }

  .reviews__pagination {
    margin-bottom: 60px;
  }

  .reviews__form-wrap {
    max-width: 100%;
  }

  .reviews__form-title {
    margin-bottom: 40px;
  }

  .reviews__text {
    text-align: center;
  }

  .reviews__contacts-block {
    margin-bottom: 0;
  }

  .reviews__contacts-title {
    margin-bottom: 20px;
  }

  .reviews__contacts-block input:last-child {
    margin-bottom: 20px;
  }

  .reviews__know-title {
    margin-bottom: 15px;
  }

  .reviews__know-wrap {
    justify-content: center;
    margin-bottom: 25px;
  }

  .reviews__know-btn {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 14px;
  }

  .footer {
    padding-bottom: 20px;
  }

  .footer__container {
    padding-top: 35px;
  }

  .footer__top {
    margin-bottom: 25px;
  }

  .footer__nav {
    display: none;
  }

  .footer__nav-md {
    display: block;
  }

  .footer__nav-md .nav__item {
    margin: 0 0 10px;
  }

  .footer__bottom {
    flex-direction: column;
  }

  .footer__logo {
    display: none;
  }

  .footer__social {
    justify-content: space-between;
    min-width: 172px;
    margin-bottom: 45px;
  }

  .footer__phone-adress a.phone-adress__text {
    position: relative;
    margin-bottom: 10px;
  }

  .footer__phone-adress a.phone-adress__text:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }

  .footer__copyright {
    position: relative;
  }

  .footer__copyright::after {
    content: "";
    position: absolute;
    top: -40px;
    left: 0;
    left: 50%;
    z-index: 20;
    display: block;
    width: 250px;
    height: 4px;
    border-radius: 7px;
    transform: translate(-50%, 0);
  }

  .footer__copy-text {
    font-size: 12px;
    line-height: 1.2;
  }

  .footer__copy-text br {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .container--800 {
    max-width: 310px;
    padding: 0 30px;
  }

  .about-us {
    padding-bottom: 60px;
  }

  .about-us__wrap-img img {
    min-width: 100%;
    height: 784px;
    object-fit: none;
  }

  .about-us__wrap-img {
    position: relative;
    bottom: 145px;
    left: 0;
  }

  .about-us__wrap-btn {
    margin-top: -345px;
  }

  .partners__wrap-block {
    justify-content: center;
    margin-bottom: 20px;
  }

  .partners__contacts {
    align-items: flex-start;
    margin: 0 auto;
  }

  .reviews__container {
    max-width: 100%;
  }
}

@media (max-width: 425.98px) {
  .header {
    background: url("../img/bc/header-bc-mob.png") no-repeat;
    background-size: 100%;
  }
}