.reviews-slider {
  margin-top: -50px;
  margin-bottom: 145px;

  @media (max-width: $md) {
    margin-bottom: 50px;
  }
}

.reviews-slider__title {
  margin-bottom: 52px;

  text-align: center;

  @media (max-width: $md) {
    margin-bottom: 30px;
  }
}

.reviews-slider__wrapper {
  margin-bottom: 113px;
  padding-top: 30px;
}

.reviews-slider__slide.swiper-slide {
  padding: 10px;

  transform: scale(1);

  transition: .2s;

  @media (max-width: $md) {
    padding: 0;
  }
}

.reviews-slider__slide.swiper-slide-active {
  position: relative;
  z-index: 100;

  transform: scale(1.2);
}

.reviews-slider__wrap-img {
  border-radius: 8px;
  box-shadow: 1px 2px 5px rgba(221, 164, 64, .85);
}

.reviews-slider__wrap-img img {
  width: 100%;

  border-radius: 8px;

  object-fit: contain;
}
