.cards-slider {
  margin-bottom: 90px;
  padding: 30px;

  @media (max-width: $xl) {
    max-width: 690px;
    margin-bottom: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (max-width: $lg) {
    max-width: 690px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (max-width: $md) {
    max-width: 225px;
    margin-bottom: 30px;
  }
}
