.container {
  position: relative;

  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding-right: 30px;
  padding-left: 30px;

  // @media (max-width: $xl) {
  //   max-width: 960px;
  //   padding-right: 15px;
  //   padding-left: 15px;
  // }

  @media (max-width: $lg) {
    max-width: 930px;
    padding-right: 15px;
    padding-left: 15px;
  }

  // @media (max-width: $lg) {
  //   max-width: 720px;
  // }

  @media (max-width: $md) {
    // max-width: 540px;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  // @media (max-width: $sm) {
  //   max-width: 100%;
  // }

  &--800 {
    max-width: 860px;

    @media (max-width: $lg) {
      max-width: 830px;
    }

    @media (max-width: $md) {
      max-width: 690px;
      padding: 0 15px;
    }

    @media (max-width: $sm) {
      max-width: 310px;
      padding: 0 30px;
    }
  }
}
