body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  overflow-x: hidden;

  font-size: $fz-main;
  font-family: $ff-main;
  color: $c-main;

  background-color: $c-main;

  &.fixed {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
}

.main-wrapper {
  position: relative;

  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;

    display: block;
    // height: 100%;

    background-size: 100%;
  }
  &::before {
    top: -323px;
    left: 0;

    width: 623px;
    height: 1250px;

    background: url("../img/bc/tinsel-left.png") no-repeat;

    @media (max-width: $lg) {
      top: -360px;

      background: url("../img/bc/tinsel-left-tab.png") no-repeat;
    }

    @media (max-width: $md) {
      top: -67px;

      width: 130px;
      height: 393px;

      background: url("../img/bc/tinsel-left-mob.png") no-repeat;
    }
  }

  &::after {
    top: -510px;
    right: 0;

    width: 603px;
    height: 1506px;

    background: url("../img/bc/tinsel-right.png") no-repeat;

    @media (max-width: $lg) {
      top: -545px;

      width: 395px;

      background: url("../img/bc/tinsel-right-tab.png") no-repeat;
    }

    @media (max-width: $md) {
      top: -125px;

      width: 125px;
      height: 393px;

      background: url("../img/bc/tinsel-right-mob.png") no-repeat;
    }
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// input auto-complete change color
:-webkit-autofill {
  box-shadow: 0 0 100px #fff inset;

  -webkit-text-fill-color: currentColor;
}
