.video-slider__box-btn {
  @media (max-width: $lg) {
    top: 50%;
  }

  @media (max-width: $md) {
    display: none;
  }
}

.video-slider__btn-prev {
  margin-left: -90px;

  @media (max-width: $lg) {
    margin-left: -50px;
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }
}

.video-slider__btn-next {
  margin-right: -90px;

  @media (max-width: $xl) {
    margin-right: 0;
  }

  @media (max-width: $lg) {
    margin-right: -50px;
  }

  @media (max-width: 991px) {
    margin-right: 0;
  }
}

.video-slider__pagination.slider-pagination {
  display: none;

  @media (max-width: $md) {
    display: flex;
  }
}

.video-slider__pagination.slider-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  @media (max-width: $md) {
    bottom: -50px;
  }
}
