.like {
  position: relative;

  padding-bottom: 50px;
}

.like__container {
  max-width: 1145px;
}

.like__title {
  margin-bottom: 50px;

  text-align: center;

  @media (max-width: $md) {
    margin-bottom: 10px;
  }

  & br {
    display: none;

    @media (max-width: $md) {
      display: block;
    }
  }
}

.like__wrap-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 160px;

  @media (max-width: $md) {
    position: relative;
    z-index: 10;

    margin-bottom: 100px;
  }
}

.like__tinsel .tinsel__left {
  top: -285px;
}

.like__tinsel .tinsel__right {
  bottom: -32.5%;
}

.like__slider-box-btn {
  top: 43%;

  @media (max-width: $md) {
    top: 48%;
  }
}

.like__slider-btn-next.btn-next {
  margin-right: 25px;
}

.like__slider.cards-slider {
  @media (max-width: $md) {
    margin-bottom: 10px;
  }
}
