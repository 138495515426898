.search {
}

.search__input {
  padding: 18px 30px 18px 45px;

  font-weight: 400;
  // font-size: $fz-main;
  // line-height: 1.2;
  // font-family: $ff-main;
  // color: #828282;
  // font-style: normal;
  // letter-spacing: .02em;

  // background-color: $c-white;
  // border: none;
  // border-radius: 30px;
  // outline: none;

  @media (max-width: $md) {
    width: 100%;
    padding: 12px 29px;

    font-size: 14px;
  }
}
