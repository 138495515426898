.title {
}

h1 {
  margin-bottom: 0;

  font-weight: 700;
  font-size: 65px;
  line-height: 75px;
  font-family: $ff-main;
  text-align: center;
  color: $c-white;
  font-style: normal;

  @media (max-width: $md) {
    font-size: 32px;
    line-height: 33px;
  }
}

h2 {
  margin-bottom: 0;

  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  font-family: $ff-main;
  text-align: left;
  color: $c-white;
  font-style: normal;

  @media (max-width: $md) {
    font-size: $fz-main;
    line-height: 25px;
  }
}

h3 {
  font-weight: 800;
  font-size: $fz-main;
  line-height: 1.2;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;

  @media (max-width: $lg) {
    font-size: $fz-main;
  }

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.title-line {
  position: relative;

  margin-bottom: 40px;

  @media (max-width: $md) {
    margin-bottom: 15px;
  }

  &::after {
    @extend %gold;

    content: "";
    bottom: 0;

    display: block;
    max-width: 800px;
    height: 4px;
    margin: 0 auto;

    border-radius: 7px;
  }
}
