.partners {
  padding-bottom: 150px;

  @media (max-width: $md) {
    padding-bottom: 40px;
  }
}

.partners__title {
  margin-bottom: 120px;

  @media (max-width: $md) {
    margin-bottom: 40px;
  }
}

.partners__wrap-text {
  margin-bottom: 75px;

  @media (max-width: $md) {
    margin-bottom: 40px;
  }
}

.partners__wrap-block {
  position: relative;
  z-index: 10;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 50px;

  @media (max-width: $sm) {
    justify-content: center;
    margin-bottom: 20px;
  }
}

.partners__block {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 205px;
  margin-bottom: 70px;
  padding: 20px;

  background-color: $c-white;
  border-radius: 26px;

  transition: .3s;

  &:hover {
    text-decoration: none;

    box-shadow: 2px 2px 44px rgba(255, 185, 29, .8);
  }

  @media (max-width: $md) {
    justify-content: space-between;
    width: 250px;
    min-height: 130px;
    margin-bottom: 20px;

    border-radius: 20px;
  }
}

.partners__wrap-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;

  @media (max-width: $md) {
    height: 64px;
  }

  & img {
    max-height: 100%;
    margin-bottom: 10px;
  }
}

.partners__block-title {
  min-height: 44px;
  margin-bottom: 0;

  font-weight: 600;
  font-size: $fz-main;
  line-height: 1.1;
  font-family: $ff-main;
  text-align: center;
  color: #333;
  font-style: normal;

  @media (max-width: $md) {
    min-height: auto;

    font-size: 13px;
  }
}

.partners__contacts {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $sm) {
    align-items: flex-start;
    margin: 0 auto;
  }

  & a {
    color: $c-white;

    &:hover {
      color: $c-white;
      text-decoration: none;
    }
  }

  & .partners__text {
    margin-bottom: 15px;
  }
}
