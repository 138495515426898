.btn-box {
  position: absolute;
  top: 50%;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-next,
.btn-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  background: url("../img/icon/arrow-left.png") no-repeat;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }

  &.swiper-button-disabled {
    opacity: .5;
  }


  @media (max-width: $md) {
    width: 20px;
    height: 20px;

    background: url("../img/icon/arrow-left-mob.png") no-repeat;
    background-size: 100%;
  }
}

.btn-next {
  transform: rotate(-180deg);

  @media (max-width: $xl) {
    margin-right: 60px;
  }

  @media (max-width: $lg) {
    margin-right: 30px;
  }
}

.btn-prev {
  margin-left: -60px;

  @media (max-width: $xl) {
    margin-left: 0;
  }
}
