.single {
  position: relative;

  margin-bottom: 165px;

  @media (max-width: $md) {
    margin-bottom: 100px;
  }
}

.single__title {
  margin-bottom: 120px;

  @media (max-width: $md) {
    margin-bottom: 40px;
  }

  & br {
    display: none;

    @media (max-width: $md) {
      display: block;
    }
  }
}

.single__wrap-video {
  position: relative;

  max-width: 1022px;
  margin: 0 auto 120px;

  @media (max-width: $lg) {
    max-width: 800px;
  }

  @media (max-width: $md) {
    margin-bottom: 110px;
  }
}

.single__wrap-text {
  margin-bottom: 120px;

  @media (max-width: $md) {
    margin-bottom: 60px;
  }
}

.single__text a.text {
  display: block;
}

.single__list {
  margin-bottom: 40px;
  padding-left: 30px;

  list-style-type: disc;
}

.single__item {
  font-weight: 400;
  font-size: $fz-main;
  line-height: 1.25;
  font-family: $ff-main;
  color: $c-white;
  font-style: normal;

  @media (max-width: $md) {
    font-size: 12px;
  }
}

.single__wrap-image {
  position: relative;

  max-width: 1080px;
  margin: 0 auto 120px;

  @media (max-width: $lg) {
    max-width: 750px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (max-width: $md) {
    max-width: 100%;
    margin-bottom: 110px;
    padding: 0;
  }
}

.single__wrap-btn {
  display: flex;
  justify-content: center;

  @media (max-width: $md) {
    position: relative;
    z-index: 10;
  }
}

.single__tinsel .tinsel__right {
  bottom: -52.5%;

  @media (max-width: $lg) {
    bottom: -70.5%;
  }
}
