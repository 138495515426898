.cards {
  padding: 160px 0 90px;

  @media (max-width: $lg) {
    padding-top: 125px;
  }

  @media (max-width: $md) {
    margin-top: -1px;
    padding-top: 55px;
    padding-bottom: 0;
  }
}

.cards__container {
  max-width: 1145px;
}

.cards__title {
  margin-bottom: 90px;

  @media (max-width: $md) {
    margin-bottom: 10px;
  }
}

.cards__wrap-btn {
  display: flex;
  justify-content: center;
}
